
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import React from 'react';

//itemArr is an array of objects that you want to sort

//OnListReorder is a callback function that runs the the function that called DragDrop
//It returns the itemArr in the updated order after the dragdrop takes place

//Property is the property of the objects that you want to sort by

const DragDrop = ({ itemArr, onListReorder, property, adminReview, download, onDownloadTeam}) => {
    let items = [];
    
    //convert the object into an array
    itemArr.forEach(element => {
      items.push(element[property])
    });

    //Runs when the item is dropped
    const handleDragEnd = (result) => {
      if (!result.destination) return;
      //Reorders the original itemArr based on the new order of the objects' property
      const newItems = Array.from(items);
      const [reorderedItem] = newItems.splice(result.source.index, 1);
      newItems.splice(result.destination.index, 0, reorderedItem);
      const reorderedObject = [];
      newItems.forEach((title) => {
        const item = Object.values(itemArr).find((obj) => obj[property] === title);
        if (item) {
          reorderedObject.push(item);
        }
      });

      //Runs the callback function and passes back the reordered itemArr
      onListReorder(reorderedObject);
    };

    const test = (teamID, teamName) => {
      onDownloadTeam(teamID, teamName);
    }
  
    return (
      <DragDropContext onDragEnd={handleDragEnd}>
        <Droppable droppableId="droppable">
          {(provided, snapshot) => (
            <div
              {...provided.droppableProps}
              ref={provided.innerRef}
              style={{
                background: 'white',
                width: '100%',
                borderRadius: '5px',
                minHeight: '50px'
              }}
              className=' d-flex flex-wrap'
            >
              {itemArr.map((item, index) => (
                <Draggable key={item[property]} draggableId={item[property]} index={index} >
                {(provided, snapshot) => (
                  <div
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    style={{
                      userSelect: 'none',
                      padding: 8,
                      width: '100%',
                      height: 'fit-content',
                      borderRadius: "5px",
                      margin: '0 0 .3rem 0',
                      background: snapshot.isDragging ? 'lightgreen' : '#f0f0f0',
                      ...provided.draggableProps.style,
                    }}
                  >
                    <span {...provided.dragHandleProps}>
                      <div className='drag-info-container'>
                        <div className='d-flex'>
                          <h4 className="drag-title flex-grow-1" >{item[property]}</h4>
                          <span className='team-score px-4'>
                            {item.score ? item.score : "No Score"}
                          </span>
                          { download &&
                          <button className='reveal-schools-btn downloadButton px-3' onClick={() => test(item.tid, item[property])}>
                                Download
                          </button>
                          }
                        </div>
                        {/* Inlcude school if admin is reviewing */}
                        {adminReview ? (
                            item.school ? (
                              <span className='team-school'>{item.school}</span>
                            ) : (
                              <span>No School</span>
                            )
                          ) : (
                            <></>
                          )}
                      </div>
                    </span>
                  </div>
                )}
              </Draggable>
              ))}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
    );
  };

export default DragDrop;