import React, { Component, Fragment } from 'react';
import '../css/index.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import Modal from 'react-bootstrap/Modal';
import { connect } from 'react-redux';
import { userActions } from '../actions/useractions';

import onePicture from '../images/Judge_1.jpg';
import twoPicture from '../images/Student_1.jpg';
 
class PageNotFound extends Component{
    constructor(props){
        super(props);
        this.state ={
        }
    }
    componentDidMount() {
    }
    render(){
        return(
            <Fragment>
                <div className='container'>
                    <center>
                        <h1>Page not found or you don't have access.</h1>
                        <h2>Check your URL</h2>
                        <br></br>
                        <br></br>
                        <br></br>

                        <h3>If you think this is a mistake,&nbsp;
                            <a href='/'>sign in</a>
                            &nbsp;and try again.</h3>
                    </center>
                </div>
            </Fragment>
        );
    }
 }
 
function mapState(state) {
    const { user } = state;
    return { user };
}

const actionCreators = {
};

export default connect(mapState, actionCreators)(PageNotFound);

