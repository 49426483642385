import React, { Component, Fragment, useState } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../../css/index.css'
import { connect } from 'react-redux';
import { userActions } from '../../actions/useractions';

import uploadIcon from '../../images/uploadWhite.png';
import deleteIcon from '../../images/deleteBlack.png';

import AddTables from './addTables';

class AddEvent extends Component {
  constructor(props) {
    super(props)
    this.state = {
      showModal: false,
      uploadType: undefined,
      eName: "",
      eRound: "",
      judgeUpld: false,
      questionUpld: false,
      teamUpld: false, 
      trackUpld: false
    }
    this.showPopup = this.showPopup.bind(this);
    this.finishedUpload = this.finishedUpload.bind(this);
  }

  updateEvent() {
    const {eventId} = this.props.lookup
    const {eName, eRound, judgeUpld, questionUpld, teamUpld, trackUpld} = this.state
    this.props.updateDB("Event", {eName, eRound, judgeUpld, questionUpld, teamUpld, trackUpld}, eventId);
  } 

  removeData(type) {
    const {eventId} = this.props.lookup
    this.props.removeEventData(type, eventId)
    switch(type) {
      case "Judges" :
        this.setState({judgeUpld: false})
        break
      case "Questions" :
        this.setState({questionUpld: false})
        break
      case "Teams" :
        this.setState({teamUpld: false})
        break
      case "Tracks" :
        this.setState({trackUpld: false})
        break
      default: 
      break
    }
  }

  deleteEvent(id) {
    this.props.deleteEvent(id)
  }

  deleteButton(){
    const {Events} = this.props.lookup
      const dataToRender = this.state.eventObjects.length > 0 ? this.state.eventObjects : Events;
      const { search } = this.state;
      const dlteButton = dataToRender.map(
        (element) => {
          return(
            <Fragment>
                  <button className="editButton" onClick={() => this.deleteEvent(element.id)}><img src={deleteIcon} alt="delete button png"/></button>
            </Fragment>
          )
      })
    return(
      <Fragment>{dlteButton}</Fragment>
    )
  }

  firstPage(){
    let {eName, eRound, judgeUpld, questionUpld, teamUpld, trackUpld} = this.state;

    return(
      <Fragment>
        <center>
          <div className='container'><form className='add-event'>
            <div>
              <input
                type="text" id="eventName" placeholder="Event Name" aria-labelledby="eventNameHelp" className='smallInput'
                value={eName}
                onChange={(e) => this.setState({eName: e.target.value})}
              />
              <div id="eventNameHelp" className="form-text eventTextMargin">
                Event Name
              </div><br/>

              <input
                type="text" id="roundName" placeholder="Round Name" aria-labelledby="roundNameHelp" className='smallInput'
                value={eRound} maxlength="25"
                onChange={(e) => this.setState({eRound: e.target.value})}
              />
              <div id="roundNameHelp" className="form-text eventTextMargin">
                Round Name
              </div>
              <br/>

              <br/>
            </div>
          </form></div>

          <div>
             {judgeUpld ? <button className='reuploadButton eventTextMarginSpec2' onClick={() => this.removeData("Judges")}>Reupload Judges</button> : <button onClick={() => this.showPopup("Judges")} className='orangeButton eventTextMargin' id="uploadJudges" aria-labelledby="uploadJudgesHelp">Upload <img src={uploadIcon} alt='Upload Icon'/></button>}
            <div id="uploadJudgesHelp" className="form-text eventTextMarginSpec">
              Upload <b>Judges</b> CSV
            </div>
            <br/>

             {questionUpld ? <button className='reuploadButton eventTextMarginSpec2' onClick={() => this.removeData("Questions")}>Reupload Questions</button> : <button onClick={() => this.showPopup("Questions")} className='orangeButton eventTextMargin' id="uploadQuestions" aria-labelledby="uploadJudgesHelp">Upload <img src={uploadIcon} alt='Upload Icon'/></button>}
            <div id="uploadJudgesHelp" className="form-text eventTextMarginSpec2">
              Upload <b>Questions</b> CSV
            </div>
            
            <br/>

             {teamUpld ? <button className='reuploadButton eventTextMarginSpec2' onClick={() => this.removeData("Teams")}>Reupload Teams</button> : <button  onClick={() => this.showPopup("Teams")} className='orangeButton eventTextMargin' id="uploadTeams" aria-labelledby="uploadJudgesHelp">Upload <img src={uploadIcon} alt='Upload Icon'/></button>}
            <div id="uploadJudgesHelp" className="form-text eventTextMarginSpec">
              Upload <b>Teams</b> CSV
            </div>
            
            <br/>

             {trackUpld ? <button className='reuploadButton eventTextMarginSpec2' onClick={() => this.removeData("Tracks")}>Reupload Tracks</button> : <button onClick={() => this.showPopup("Tracks")} className='orangeButton eventTextMargin' id="uploadTracks" aria-labelledby="uploadJudgesHelp">Upload <img src={uploadIcon} alt='Upload Icon'/></button>}
            <div id="uploadJudgesHelp" className="form-text eventTextMarginSpec">
              Upload <b>Tracks</b> CSV
            </div>
            
            <br/>
          </div>

          <div className='container'>
            <div className='row'>
              <div className='col-2'></div>
              <div className='col-4'>
                <a href='/admin/dashboard'><button className='cancelButton'>Back</button></a>
              </div>
              <div className='col-4'>
                <button onClick={() => this.updateEvent()} className='nextButton'>Submit</button>
              </div>
              <div className='col-2'></div>
            </div>
          </div><br/>
        </center>
      </Fragment>
    )
  }

  addButton(){
    return(
      <Fragment>
        <button className="" onClick={() => this.showPopup()}>+</button>
      </Fragment>
    )
  }

  showPopup(e) {
    this.setState({ uploadType: e }, () => {
      this.flipModal();
    });
  }
  
  flipModal() {
    this.setState((prevState) => ({
      showModal: !prevState.showModal,
    }));
  }

  finishedUpload(type) {
    switch(type) {
      case "Judges" :
        this.setState({judgeUpld: true})
        this.showPopup(type)
        break
      case "Questions" :
        this.setState({questionUpld: true})
        this.showPopup(type)
        break
      case "Teams" :
        this.setState({teamUpld: true})
        this.showPopup(type)
        break
      case "Tracks" :
        this.setState({trackUpld: true})
        this.showPopup(type)
        break
      default: 
      break
    }
  }

  render() {
    const {uploadType} = this.state
    return (
      <Fragment>
        { this.state.showModal &&
          <div className="modal-main">
            <AddTables type={uploadType} finishedUpload={this.finishedUpload}/>
            <button onClick={() => this.showPopup()}>X</button>
          </div>                      
        }
        
        {/* When adding a question the question pop up menu will appear here! */}
        <div className='centered'>
          <div className="header">
            <h1>Event Creation Portal</h1>
            <button className="deleteButton" onClick={() => this.deleteEvent()}>
              Delete <img src={deleteIcon} alt="delete button png"/>
            </button>
          </div>
        </div>
        {this.firstPage()}
      </Fragment>
    );
  }
}

function mapState(state) {
  const {session, lookup} = state;
  return {session, lookup};
}

const actionCreators = {
  updateDB: userActions.updateDB,
  removeEventData: userActions.removeEventData,
  deleteEvent: userActions.deleteEvent,
};

export default connect(mapState, actionCreators)(AddEvent);