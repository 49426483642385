import React, { Component, Fragment, useState } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../../css/index.css'
import { connect } from 'react-redux';
import { userActions } from '../../actions/useractions';

import uploadIcon from '../../images/uploadWhite.png';
import deleteIcon from '../../images/deleteBlack.png';


import AddTables from './addTables';

class AddEvent extends Component {
  constructor(props) {
    super(props)
    this.state = {
      showModal: false,
      uploadType: undefined,
      eName: "",
      eRound: "",
      
      eJudgeName: "",
      eEmailName: "",
      eRoomName: "",
      eTracksName: "",
      eCodeName: "",
      
      judgeUpld: true,
      trackUpld: true,

      propToStateUpdated: false,
    }
  }

  componentDidMount() {
    const {id} = this.props
    this.props.GetEvents(id);
  }

  componentDidUpdate() {
    const {propToStateUpdated} = this.state
    const {Events} = this.props.lookup

    if(!propToStateUpdated && Events.length > 0) {
      console.log(Events[0])
      this.setState({eName: Events[0].event_name, eRound: Events[0].round,
        judgeUpld: Events[0].uploaded_judges,trackUpld: Events[0].uploaded_tracks, propToStateUpdated: true})
    }
  }

  updateEvent() {
    const {id} = this.props
    console.log(id)
    const {eJudgeName, eEmailName, eRoomName, eTracksName, eCodeName} = this.state
    this.props.addJudge({eJudgeName, eEmailName, eRoomName, eTracksName, eCodeName}, id);
  } 

  deleteEvent() {
    const {id} = this.props
    this.props.deleteEvent(id)
  }

  deleteButton(){
    const {Events} = this.props.lookup
      const dataToRender = this.state.eventObjects.length > 0 ? this.state.eventObjects : Events;
      const { search } = this.state;
      const dlteButton = dataToRender.map(
        (element) => {
          return(
            <Fragment>
                  <button className="editButton" onClick={() => this.deleteEvent(element.id)}><img src={deleteIcon} alt="delete button png"/></button>
            </Fragment>
          )
      })
    return(
      <Fragment>{dlteButton}</Fragment>
    )
  }

  secondPage(){
    let {eJudgeName, eEmailName, eRoomName, eTracksName, eCodeName} = this.state;
    const {Events} = this.props.lookup
    
    return(
      <Fragment>
        <center>
          <h4>Adding New Judge</h4>
          <div className='container'><form className='add-event'>
            <div>
              <input
                type="text" id="judgeName" placeholder="Judge Name" aria-labelledby="judgeNameHelp" className='smallInput'
                value={eJudgeName}
                onChange={(e) => this.setState({eJudgeName: e.target.value})}
              />
              <div id="judgeNameHelp" className="form-text eventTextMargin">
                Add Judge
              </div><br/>
              
              <input
                type="text" id="emailName" placeholder="Judge Email" aria-labelledby="emailNameHelp" className='smallInput'
                value={eEmailName}
                onChange={(e) => this.setState({eEmailName: e.target.value})}
              />
              <div id="emailNameHelp" className="form-text eventTextMargin">
                Add Email
              </div><br/>

              <input
                type="text" id="trackName" placeholder="Track(s)" aria-labelledby="TrackNameHelp" className='smallInput'
                value={eTracksName}
                onChange={(e) => this.setState({eTracksName: e.target.value})}
              />
              <div id="TrackNameHelp" className="form-text eventTextMargin">
                Add Track(s)
              </div><br/>

              <input
                type="text" id="roomName" placeholder="Room Name" aria-labelledby="roomNameHelp" className='smallInput'
                value={eRoomName}
                onChange={(e) => this.setState({eRoomName: e.target.value})}
              />
              <div id="roomNameHelp" className="form-text eventTextMargin">
                Add Room(s)
              </div><br/>

              <input
                type="text" id="codeName" placeholder="Judge Code" aria-labelledby="codeNameHelp" className='smallInput'
                value={eCodeName}
                onChange={(e) => this.setState({eCodeName: e.target.value})}
              />

              <div id="codeNameHelp" className="form-text eventTextMargin">
                Add Code
              </div><br/>
              <br/>
              <br/>
            </div>
          </form></div>

          <div className='container'>
            <div className='row'>
              <div className='col-2'></div>
              <div className='col-4'>
                <a href='/admin/dashboard'><button className='cancelButton'>Back</button></a>
              </div>
              <div className='col-4'>
                <button onClick={() => this.updateEvent()} className='nextButton'>Submit</button>
              </div>
              <div className='col-2'></div>
            </div>
          </div><br/>
        </center>
      </Fragment>
    )
  }

  render() {
    return (
      <Fragment>   
        {/* When adding a question the question pop up menu will appear here! */}
        <center>
          <div className='header'>
            <h1>Edit Event Portal</h1>
            <button className="deleteButton" onClick={() => this.deleteEvent()}>
                Delete <img src={deleteIcon} alt="delete button png"/>
            </button>
          </div><br/>
        </center>
        {this.secondPage()}
      </Fragment>
    );
  }
}

function mapState(state) {
  const {session, lookup} = state;
  return {session, lookup};
}

const actionCreators = {
  updateDB: userActions.updateDB,
  removeEventData: userActions.removeEventData,
  GetEvents: userActions.GetEvents,
  deleteEvent: userActions.deleteEvent,
  addJudge: userActions.addJudge,
};

export default connect(mapState, actionCreators)(AddEvent);