import React, { userCallback, Component, Fragment, useState } from 'react';
import { connect } from 'react-redux';
import { userActions } from '../../actions/useractions';
import Papa from "papaparse";

class addTables extends Component {
    constructor(props) {
        super(props)
        this.state = {
            table: undefined,
            columns: [],
            data: [],
            error: false,
            errorString: '',
            tableLoaded: false,
            canUpload: false,
            
        }

        this.processFile = this.processFile.bind(this);
        this.tableHeaders = this.tableHeaders.bind(this);
        this.tableData = this.tableData.bind(this);
        this.updateTable = this.updateTable.bind(this);
        this.clearFile = this.clearFile.bind(this);

    }

    componentDidMount() {
    }

    updateTable() {
        const {eventId} = this.props.lookup
        const {data} = this.state
        const {type} = this.props
        if(data) {
            this.props.updateDB(type, data, eventId);
            this.props.finishedUpload(type)
        } 

    }


    handleFileChange = (e) => {
        const allowedExtensions = ["csv"];
        this.setState({canUpload: false})

        // Check if user has selected a file
        if (e.target.files.length) {
          const inputFile = e.target.files[0];
      
          // Extract the file extension from the file name
          const fileName = inputFile.name;
          const fileExtension = fileName.substring(fileName.lastIndexOf(".") + 1);
      
          // Check the file extension against the allowed extensions
          if (!allowedExtensions.includes(fileExtension)) {
            this.setState({ errorString: 'Wrong File Type' });
            document.getElementById("FileInput").value = '';
            return;
          }
      
          // If the file extension is correct, set the state
          this.setState({ table: inputFile });
        }
      };


    processFile = () => {
        const {table} = this.state

        // If user clicks the parse button without
        // a file we show a error
        if (!table) return this.setState({ errorString: 'No File Inputted' })

        this.setState({canUpload: true})
        const reader = new FileReader();
        reader.onload = async ({ target }) => {
            const csv = Papa.parse(target.result, { header: true });
            const parsedData = csv?.data;
            const columns = Object.keys(parsedData[0]);
            this.setState({ columns: columns });
            this.setState({ data: parsedData });
            this.setState({ tableLoaded: true });
        };
        reader.readAsText(this.state.table);

    };

    tableHeaders() {
        const columns = this.state.columns;
        const tableHeaders = columns.map(
            (element) => {
                return (
                    <th>{element}</th>
                )
            }
        )
        return (
            <Fragment>{tableHeaders}</Fragment>
        )

    }

    //Currently looking for a dynamic solution akin to 
    tableData() {
        const rows = this.state.data;
        const tableRows = rows.map(
            (element) => {
                return (
                    <tr>
                        {Object.keys(element).map((key) => {
                            return (
                                <td>{element[key]}</td>
                            )
                        })}
                    </tr>
                )
            }
        )
        return (
            <Fragment>{tableRows}</Fragment>
        )
    }

    clearFile() {
        document.getElementById('FileInput').value = '';
        this.setState({ table: '' });
        this.setState({ tableLoaded: false })

    }



    render() {
        const {type} = this.props;
        const {canUpload} = this.state;
        return (
            <Fragment>
                <center className='modal-body'>
                <div className='mx-3 w-75'>
                    <div className="text-center tableView">
                        {/* For Testing Purposes Will Be Set Another Way Later On!*/}
                        <br />
                        <p>Upload: {type}</p>
                        <div className="tableOptions d-flex">
                            <input id="FileInput" className="form-control" type="File" name="file" onChange={this.handleFileChange} />
                            {/*<button className="btn btn-danger" onClick={() => this.clearFile()}>X</button>*/}
                        </div>
                        <br />
                        <button className="blueButtonNormal" onClick={() => this.processFile()}>Upload</button>
                        {canUpload ? <button className="orangeButton" onClick={() => this.updateTable()}>Confirm</button> : <p>View before uploading</p>}
                    </div>
                    <table className="tableFormatting">
                        <tr>{this.tableHeaders()}</tr>
                        <Fragment>{this.tableData()}</Fragment>
                    </table>
                </div>
                </center>
            </Fragment>
        );
    }
}


function mapState(state) {
    const { session, lookup } = state;
    return { session, lookup };
}

const actionCreators = {
    updateDB: userActions.updateDB
};

export default connect(mapState, actionCreators)(addTables);