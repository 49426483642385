import React, { Component, Fragment, useState, createRef } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { connect } from 'react-redux';
import { userActions } from '../../actions/useractions';
import editIcon from '../../images/editWhite.png';
import saveIcon from '../../images/save.png';

//Please keep this in (un-commented), they may look useless but for some random reason navbar used on other pages love these two.
import { Button } from 'bootstrap';
import { current } from '@reduxjs/toolkit';

class Judges extends Component{
    constructor(props){
        super(props);
        this.state = {
            currentTeam: {},
            scoresArray: [], 
            pointValues : [
                1, 2, 3, 4, 5, 6, 7, 8, 9, 10
            ],
            myTeams: [],
            comment: "",
            comments: [],
            noTeamInfo: false
        }
            this.updateCurrentTeam = this.updateCurrentTeam.bind(this);
            this.ratingsContainerRef = createRef();
        }

        componentDidUpdate(){
            const {scoresArray, comments, noTeamInfo} = this.state
            const {myTeamInfo} = this.props.lookup.MyTeamInfo
            const {session} = this.props

            if(scoresArray.length < 1 && myTeamInfo && session && !noTeamInfo) {

                myTeamInfo.forEach(element => {
                    let scoreInitializer = {team: element.team_id, questionAnswers: []}

                    //This value can't be null or swapping between comments won't work
                    let commentInitializer = null;
                    if(element.comment) {
                        commentInitializer = {team: element.team_id, comment: element.comment, is_shareable : element.is_shareable}
                    } else {
                        commentInitializer = {team: element.team_id, comment: "", is_shareable : element.is_shareable}
                    }

                    scoresArray.push(scoreInitializer)
                    comments.push(commentInitializer)
                });

                this.setState({scoresArray: scoresArray})
                this.setState({comments: comments})

                if(scoresArray.length < 1) this.setState({noTeamInfo: true})
            }
        }

        updateCurrentTeam(Team) {
            this.setState({comment: ""})
            const buttons = document.querySelectorAll('input[type="radio"]');
            buttons.forEach(button => (button.checked = false));
            this.setState({ currentTeam: Team });
        }

    saveScores(question_id, val, currentTeam) {
        const {judgeID} = this.props.session
        const {scoresArray} = this.state
        const data = {
            judge_id: judgeID,
            tid: currentTeam.team_id,
            qid: question_id,
            value: val
        }

        let newObj = {question: question_id, score: val}
        scoresArray.forEach(element => {
            if(element.team === currentTeam.team_id) {
                const existingObject = element.questionAnswers.find(obj => obj.question === question_id);
                if(existingObject) {
                    const updatedArray = element.questionAnswers.map(obj => {
                        if (obj.question === question_id) {
                            return { ...obj, score: val }
                        } else {
                            return obj
                        }
                    });   
                    element.questionAnswers = updatedArray
                } else {
                    element.questionAnswers.push(newObj)
                }
            }
        });

        this.setState({scoresArray: scoresArray})

        this.props.updateDB('scores', data, this.props.session.eventID);
    }

    SaveComments(team_id, comment, bool){        
        const data = {
            event_id: this.props.session.eventID,
            judge_id: this.props.session.judgeID,
            tid: team_id,
            value: comment,
            is_shareable: bool
        }
        this.props.updateDB('comments', data, this.props.session.eventID);
        this.props.getMyTeamInfo(this.props.session.eventID, this.props.session.judgeID);
    }

    displayJudgeInfo(){
        const {name, track, room} = this.props.session;
        return(
            <Fragment>
                <div className='col-4'>
                    <h2>Name: <b>{name}</b></h2>
                </div>
                <div className='col-3'>
                    <h2>Track: <b>{track}</b></h2>
                </div>
                <div className='col-5 rightText'>
                    <h2>Room: <b>{room}</b></h2>
                </div>
            </Fragment> 
        )
    }

    displayTeamInfo(){
        const buttons = document.querySelectorAll('.button2');
        buttons.forEach(button => {
          button.addEventListener('click', () => {
            buttons.forEach(button => button.classList.remove('on2'));
            button.classList.add('on2');
            });
        });

        const key = "team_name"
        const {myTeamInfo} = this.props.lookup.MyTeamInfo

        const teamInfo = myTeamInfo.map(
            (element) => {
                return(
                    <Fragment>
                            <tr>
                                <button className='transparent button2' onClick={() => this.updateCurrentTeam(element)}>

                                    <td>
                                        <div className='textWidth textMargin'>
                                            <h5 className='leftText'><b>{element.team_name}</b></h5>
                                            <p className='leftText'>{element.track}</p>
                                        </div>
                                    </td>
                                    <td className='rightPadding bigText'><b>{element.avg_score != null ? element.avg_score : "N/A"}</b></td>
                                    <td className='buttonPadding'><button onClick={() => this.updateCurrentTeam(element)} className="marginTableJudge noButtonCover button" type="button"><img src={editIcon} alt='edit icon'/></button></td>
                                </button>
                            </tr>
                    </Fragment>
                )
            }
        )
        return(
            <Fragment>{teamInfo}</Fragment>   
        )
    }

    updateComment(e, team) {
        const { comments } = this.state;

        let updatedComment;
        
        if(typeof e === "boolean") {
            updatedComment = comments.map((comment) =>
            comment.team === team.team_id ? { ...comment, is_shareable: e } : comment
            );
        } else {
            updatedComment = comments.map((comment) =>
            comment.team === team.team_id ? { ...comment, comment: e } : comment
            );
        }

        this.setState({ comments: updatedComment });
      }

    questionSection = (props) => {        
        const {Questions} = this.props.lookup;
        var i = 0;
        const {currentTeam, comments} = this.state;
        const {myTeamInfo} = this.props.lookup.MyTeamInfo

        const lookupCurrentTeam = myTeamInfo.find((obj) => obj.team_id === currentTeam.team_id);
        const lookupComment = comments.find((obj) => obj.team === currentTeam.team_id);

        if(myTeamInfo && lookupCurrentTeam && lookupComment) {
            return(
                <Fragment>
                    <div className="tab-content">
                        <div class="tab-pane fade show active">
                        <div className='col-12'>
                            <div className='row lightGreyBackground'>
                                <div className='col-6'>
                                    <h2><b>{currentTeam.team_name}</b></h2>
                                    <p>{currentTeam.track}</p>
                                </div>
                                <div className='col-6 rightText'>
                                    <h3>Average Score: <b><ul>{lookupCurrentTeam.avg_score ? lookupCurrentTeam.avg_score : "N/A"}</ul></b></h3>
                                </div>
                                <p className='redFont'>*Scores are automatically saved.</p>

                            </div><br/>
                            <div className='marginTableJudge'>
                                
                            {Questions.map(
                            (element, index) => { 
                                if(element.text != "") {
                                    return(
                                        <Fragment>
                                            <div className='container'>
                                                <div className='row'>
                                                    <div className='col-8'>
                                                        <h6><b>{index + 1}. {element.text}</b> <i className='smallText'>({element.weight}%)</i></h6>
                                                    </div>
                                                    <form className='col-4'>
                                                        {this.ratingBar(element.qid, currentTeam)}
                                                    </form>
                                                </div>
                                            </div><br/>
                                        </Fragment>
                                    ) 
                                }
                            })}

                            </div>
                            <div className=''>
                                <div className='row lightGreyBackgroundBottom'>
                                        <div className='col-2'>
                                            <h2>Comments:</h2>
                                        </div>
                                        <div className='col-10'>
                                            <textarea id="comments" name="comments" rows="3" maxlength="510" value={lookupComment.comment} onChange={(e) => this.updateComment(e.target.value, currentTeam)}></textarea>
                                            <p className="noButtonCover">Comments have a 510 character limit.</p>
                                        </div>
                                        <div className='col-6'>
                                            <br/>
                                            <p>
                                                <b>
                                                    <input type="checkbox" checked={lookupComment.is_shareable}  onChange={(e) => this.updateComment(e.target.checked, currentTeam)}></input> Would you like Venture College to share your name and contact information with this team?
                                                </b>
                                            </p>
                                        </div>
                                        <div className='col-4'></div>
                                        <div className='col-2'>
                                            <br/>
                                            <a href="#top"><button className='saveOrangeButton' onClick={() => this.SaveComments(currentTeam.team_id, lookupComment.comment, lookupComment.is_shareable)}>
                                            <img src={saveIcon} alt="save icon" />&nbsp;Save</button></a>          
                                        </div>
                                    </div>    
                                </div>
                            </div>
                        </div>
                    </div>
                </Fragment>
            )
        }
    }

    placeholderText(){
        return(
            <Fragment>
                <div className="tab-content">
                    <div class="tab-pane fade show active">
                    <div className='col-12'>
                        <div className='row placeholderTop'></div><br/>
                            <div className='marginTableJudge'>
                                <center>
                                    <h1>Please select a team to start grading.</h1>
                                </center>
                            </div>
                            <div className='row placeholderBottom'></div>
                        </div>
                    </div>
                </div>
            </Fragment>
        )
    }


    ratingBar = (question_id, currentTeam) =>{
        const {scoresArray} = this.state
        let checkedAnswers = []
        let isChecked = false
        const teamObject = scoresArray.find(team => team.team === currentTeam.team_id);
        if(teamObject) {
            checkedAnswers = teamObject.questionAnswers;
        }
        return (this.state.pointValues.map(
        (element, index) => {
            isChecked = false;
            checkedAnswers.forEach(question => {
                if(question.question === question_id && element === question.score) {
                    isChecked = true;
                }
            });
            return(
                <Fragment>
                    <div className='rateButton'>
                        <input checked={isChecked} type="radio" name='ratings' value={index} onClick={() => this.saveScores(question_id, element, currentTeam)}/>
                        <label for={index}>{element}</label>
                    </div>
                </Fragment>
            )
        })
        )
    }

    
    render(){
        if(this.props.lookup.MyTeamInfo.length === 0 && this.props.session)
        {
            this.props.getMyTeamInfo(this.props.session.eventID, this.props.session.judgeID);
            this.props.findQuestions(this.props.session.eventID);
        }
        const hasText = this.state.currentTeam;
        return(
            
            <Fragment>
                {this.props.lookup.MyTeamInfo.length !== 0 ? 
                <div className='noMargin' id ="top">
                    <div className='row'>
                        <div className='col-12'>
                            <div className='container'>
                                <div className='row'>
                                    {this.displayJudgeInfo()}
                                </div>
                            </div>
                        </div>

                        <div className="d-flex align-items-start">
                            <div className="nav flex-column nav-pills me-3 blueBackground" id="v-pills-tab" role="tablist" aria-orientation="vertical">
                                <div className='row'>
                                    <div className='col-6'>
                                        <table>
                                            {this.displayTeamInfo()}
                                        </table>
                                    </div>
                                </div>
                            </div>
                            <div className='col-8'>
                                {Object.keys(hasText).length !== 0 ? this.questionSection() : this.placeholderText()}
                            </div>
                        </div>
                    </div>
                </div>
                : "Loading"}
            </Fragment> 
        );
    }
}

function mapState(state) {
    const {session, lookup} = state;
    return {session, lookup};
}
  
const actionCreators = {
    //findTeams: userActions.GetTeams,
    findQuestions: userActions.GetQuestions,
    updateDB: userActions.updateDB,
    //getScores: userActions.GetScores,
    //updateScores: userActions.HandleScoreChange,
    getMyTeamInfo: userActions.GetMyTeamInfo,
};

export default connect(mapState, actionCreators)(Judges);