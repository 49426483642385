import { userConstants } from './constants';

let token = JSON.parse(localStorage.getItem('user')) || {};
const initialState = token || {};


export function session(state = initialState, action) {
    console.log("This is firing: " + action.type);
    switch (action.type) {
        case userConstants.REQUEST:
            return state;
        case userConstants.LOGIN:
            localStorage.setItem('user', JSON.stringify(action.userInfo));
            return action.userInfo;
        case userConstants.LOGINFAILURE:
            return state;
        case userConstants.LOGOUT:
            localStorage.removeItem('user');
            return state;
        case userConstants.GETTEAMS:
            localStorage.setItem('Teams', JSON.stringify(action.team));
            return state;
        default:
            return state;
    }
}


/*
export function session(state = INITIALSTATE, action) {
    const nextState = { ...state };
    // if (state.clearance != "admin") {
        // localStorage.removeItem("example")
    // }
    switch (action.type) {
        // case constants.exampleSUCCESS:
        //     localStorage.setItem('example', JSON.stringify(action.example));
        //     return { ...state, example: action.example }
        default:
            return state;
    }
} */
