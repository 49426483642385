import React, { Component, Fragment } from 'react';
import '../css/index.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import Modal from 'react-bootstrap/Modal';
import { connect } from 'react-redux';
import { userActions } from '../actions/useractions';

import onePicture from '../images/Judge_1.jpg';
import twoPicture from '../images/Student_1.jpg';
 
class Home extends Component{
    constructor(props){
        super(props);
        this.state ={
        }
    }

    componentDidMount() {
        //Calls the testAction function on load
        //this.props.testAction()
    }
    render(){
        return(
            <Fragment>
                <div className='container'>
                    <div className='row'>
                        <div className='col-5'>
                            <center>
                                <h1>Are you a judge?</h1>
                                <p>Thank you for volunteering to be a judge for Venture College Pitch Competitions. We truly couldn't do this without you, and appreciate your support for our student entrepreneurs across the state. Please login using your email and code, sent to you ahead of time via email, to view and access your scorecards for the pitch competition.</p>
                                <a href='/signinJudge'><button className='buttonHome'>Judges Sign In</button></a><br/><br/>
                                <img className='img' src={onePicture} alt="Image"/>
                            </center>
                        </div>
                        <div className='col-2'></div>
                        <div className='col-5'>
                            <center>
                                <img className='img' src={twoPicture} alt="Image"/><br/><br/>
                                <h1>Are you a student?</h1>
                                <p>Welcome! Planning to participate in a pitch competition? Please follow the link below to find your Zoom link, Pitch Competition schedule, and more information on the program.</p>
                                <a href='https://www.boisestate.edu/venturecollege/'><button className='buttonHome'>Learn More Here</button></a>
                            </center>
                        </div>
                    </div>
                </div>
                <br/>
            </Fragment>
        );
    }
 }
 
function mapState(state) {
    const { user } = state;
    return { user };
}

const actionCreators = {
    testAction: userActions.testAction,
    getInfo : userActions.getInfo
};

export default connect(mapState, actionCreators)(Home);

