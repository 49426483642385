import { userConstants, alertConstants } from "../redux/constants";
import { store, history } from '../_helpers';

import {io} from 'socket.io-client';

const API = process.env.REACT_APP_API_DOMAIN || 'https://boisestatepitchcompetitions.com';

let socket = io(API, {
    "force new connection": true,
    "reconnectionAttempts": "Infinity",
    "timeout": 10000,
    "transports": ["websocket"]
});

console.log("API:" + API);
console.log("Socket:" + socket);


export const userActions = {
testAction,
getInfo,
loginJudge,
reroute,
GetQuestions,
updateDB,
GetEvents,
GetLiveJudgeInfo,
GetLiveTeamInfo,
GetComments,
CreateEvent,
//HandleScoreChange,
GetMyTeamInfo,
removeEventData,
mapJudgeTrack,
deleteEvent,
getJudgeResults,
LoginAdmin,
AttemptPublish,
GetTracks,
signOut,
addJudge,
};

//Simple action to show an example
function testAction() {
    return (dispatch) => {
    dispatch(request("Testing action now!"));
    socket.emit('test', (response) => {
        console.log(response);
        dispatch(success(response));
    });
    };

    function success(message) {
    return { type: userConstants.SUCCESS, message };
    }

    function request(info) {
    return { type: userConstants.REQUEST, info };
    }
}

function addJudge(JudgeInfo, eventId) {
    return (dispatch) => {
    dispatch(request("Adding Judge"));

    socket.emit('addJudge', JSON.stringify({JudgeInfo, eventId}),
        function (res) {
        const {message, err} = JSON.parse(res);
        if(message) {
            dispatch(success({message}));
            reroute("admin/dashboard")
        }
        if (err) console.log("Error Caught!");
        }
    )
    };
    function success(message) {
    return { type: userConstants.SUCCESS, message };
    }
    function request(info) {
    return { type: userConstants.REQUEST, info };
    }
}

function signOut(user) {
    return (dispatch) => {
        dispatch(request("Signing Out"));
        if(user) dispatch(signout(user))
        reroute("")
    }
    function request(info) {return { type: userConstants.REQUEST, info }}
    function signout(info) {return { type: userConstants.LOGOUT, info }}
}

function removeEventData(type, eventId) {
    return (dispatch) => {
    dispatch(request("Removing Event Data"));
    console.log(eventId)
    socket.emit('removeEventData', JSON.stringify({type, eventId}),
        function (res) {
        const {message, err} = JSON.parse(res);
        if(message) {
            // dispatch(success({message}));
            // window.location.reload(false);
        }
        if (err) console.log("Error Caught!");
        }
    )
    };
    function success(message) {
    return { type: userConstants.SUCCESS, message };
    }
    function request(info) {
    return { type: userConstants.REQUEST, info };
    }
}

function deleteEvent(eventId) {
    return (dispatch) => {
    dispatch(request("Removing Event"));
    console.log(eventId)
    socket.emit('deleteEvent', JSON.stringify({eventId}),
        function (res) {
            const {message, err} = JSON.parse(res);
            if(message) dispatch(success(message))
            reroute('admin/dashboard');
        }
    )
    };
    function success(message) {
    return { type: userConstants.SUCCESS, message };
    }
    function request(info) {
    return { type: userConstants.REQUEST, info };
    }
}

//Simple action to show an example
function CreateEvent() {
    return (dispatch) => {
    dispatch(request("Creating event"));
    socket.emit('createEvent', (response) => {
        const responseObject = JSON.parse(response);
        if(responseObject.insertId) {
            dispatch(success(responseObject.insertId)); //Store the id of the created event in local storage
            reroute('admin/addevent');
        }
    });
    };
    function success(eventId) {
    return { type: userConstants.CREATEEVENT, eventId };
    }
    function request(info) {
    return { type: userConstants.REQUEST, info };
    }
}

//Causing a weird strobe effect but it's working
function getInfo(chat, person) {
    return (dispatch) => {
    dispatch(request("Sending Info Now!"));
    socket.emit('getInfo', JSON.stringify({chat, person}),
        function (res) {
        const {message, err} = JSON.parse(res);
        if(message) {
            dispatch(success({message}));
            window.location.reload(false);
        }
        if (err) console.log("Error Caught!");
        }
    )
    };
    function success(message) {
    return { type: userConstants.SUCCESS, message };
    }
    function request(info) {
    return { type: userConstants.REQUEST, info };
    }
}

/**
 * Verifies that the inputed code and email is correct.
 * @param {} email - email inputed by the judge
 * @param {} code - code inputed by the judge
 * @returns 
 */
function loginJudge(email, code) {
    return (dispatch) => {
    //Dispatches a request which will return state to the console
    dispatch(request("Logging In Now"));
    //Emits a request to the server side to find the socket login function with the parameters
    socket.emit('loginJudge', JSON.stringify({email, code}),
        //The response is caught here in this function and is broken down into the variables that were pushed back
        function(res) {
        const {login, judgeInfo, clearance} = JSON.parse(res);
        //If the login boolean is true then the username is pushed forward with the clearance being updated as well
        if(login) {
            const name = judgeInfo[0].judge_name;
            const judgeID = judgeInfo[0].jid;
            const eventID = judgeInfo[0].event_id;
            const track = judgeInfo[0].track;
            const room = judgeInfo[0].room;
            console.log("MADE IT HERE!");
            dispatch(success({name, email, clearance, track, room, judgeID, eventID}));
            reroute('judges');
        }
        //Else an error is thrown as the login was not found.
        else
        {
            const err = "Login was not found";
            dispatch(error(err));
        }
        }
    )
    }
    //These three functions are what the dispatch uses to send information back to the webpage
    function success(userInfo) {
        console.log(userInfo)
    return { type: userConstants.LOGIN, userInfo};
    }
    function error(err) {
    return { type: userConstants.LOGINFAILURE, err };
    }
    function request(info) {
    return { type: userConstants.REQUEST, info };
}
}

/**
 * Verifies that the inputed code and email is correct.
 * @param {} email - email inputed by the judge
 * @param {} code - code inputed by the judge
 * @returns 
 */
function LoginAdmin(email, password) {
    return (dispatch) => {
        //Dispatches a request which will return state to the console
        dispatch(request("Logging In Admin Now"));
        //Emits a request to the server side to find the socket login function with the parameters
        socket.emit('LoginAdmin', JSON.stringify({email, password}),
            //The response is caught here in this function and is broken down into the variables that were pushed back
            function(res) {

                const {login, clearance} = JSON.parse(res);
                //If the login boolean is true then the username is pushed forward with the clearance being updated as well
                if(login) {
                    console.log("MADE ADMIN HERE!");
                    dispatch(success({email, clearance}));
                    reroute('admin/dashboard');
                }
                //Else an error is thrown as the login was not found.
                else
                {
                    const err = "Login was not found";
                    dispatch(error(err));
                }
            }
        )}
        //These three functions are what the dispatch uses to send information back to the webpage
        function success(userInfo) {
            console.log(userInfo)
        return { type: userConstants.LOGIN, userInfo};
        }
        function error(err) {
        return { type: userConstants.LOGINFAILURE, err };
        }
        function request(info) {
        return { type: userConstants.REQUEST, info };
    }
}


function GetComments() {
    return (dispatch) => {
    dispatch(request("Finding Comments"));
    socket.emit('GetComments', (response) => {
        console.log(response);
        dispatch(success(response));
    });
    };

    function success(teams) {
    return { type: userConstants.GETCOMMENTS, teams };
    }

    function request(info) {
    return { type: userConstants.REQUEST, info };
    }
}

function GetQuestions(data) {
    return (dispatch) => {
    dispatch(request("Finding Questions"));
    socket.emit('GetQuestions', JSON.stringify({data}), (response) => {
        dispatch(success(response));
    });
    };

    function success(questions) {
        return { type: userConstants.GETQUESTIONS, questions };
    }

    function request(info) {
        return { type: userConstants.REQUEST, info };
    }
}

function GetEvents(id) {
    return (dispatch) => {
    dispatch(request("Finding Events"));
    socket.emit('GetEvents', JSON.stringify({id}),
        function(res) {
            dispatch(success(res));
        }
    )
    };

    function success(events) {
    return { type: userConstants.GETEVENTS, events };
    }

    function request(info) {
    return { type: userConstants.REQUEST, info };
    }
}

function GetLiveJudgeInfo(data) {
    return (dispatch) => {
    console.log(data)
    dispatch(request("Finding Live Judge Info"));
    socket.emit('GetLiveJudgeInfo', JSON.stringify({data}),
        function(res) {
        const {query, err} = JSON.parse(res);
        dispatch(success(res));
        if(err) dispatch(error(err));
        }
    )
    }

    function success(judgeData) {
    return { type: userConstants.GETLIVEJUDGEINFO, judgeData };
    }

    function request(info) {
    return { type: userConstants.REQUEST, info };
    }

    function error(err) {
    return { type: userConstants.FAILURE, err };
    }
}

function GetLiveTeamInfo(data) {
    return (dispatch) => {
        console.log(data)
        dispatch(request("Finding Live Team Info"));
        socket.emit('GetLiveTeamInfo', JSON.stringify({data}),
            function(res) {
                console.log("res")
                console.log(res)
            //const {query, err} = JSON.parse(res);
            dispatch(success(res));
            //if(err) dispatch(error(err));
            }
        )
        }

    function success(teamData) {
    return { type: userConstants.GETLIVETEAMINFO, teamData };
    }

    function request(info) {
    return { type: userConstants.REQUEST, info };
    }
}

function GetTracks(data) {
    return (dispatch) => {
        console.log(data)
        dispatch(request("Finding Track Info"));
        socket.emit('GetTracks', JSON.stringify({data}),
            function(res) {
                console.log("res")
                console.log(res)
            //const {query, err} = JSON.parse(res);
            dispatch(success(res));
            //if(err) dispatch(error(err));
            }
        )
        }

    function success(trackData) {
    return { type: userConstants.GETTRACKS, trackData };
    }

    function request(info) {
    return { type: userConstants.REQUEST, info };
    }
}



//pass in event id team id and judge id (only use event id and team id to filter)
function calculateAverage(data)
{
    return(dispatch) => {
        dispatch(request("Calculating: Fetching Score Info"));
        socket.emit('FetchScores', JSON.stringify({data}), 
        function(res)
        {
            //data.judge_id
            var team_total = 0;
            var judge_total = 0;
            var team_weight_total = 0;
            var judge_weight_total = 0;
            var total_weighted_average = 0;
            var judge_weighted_average = 0;
            const {score_info} = JSON.parse(res)
            for (let i = 0; i < score_info.length; i++) 
            {
                
                if(score_info[i].value != null)
                {
                    console.log(score_info[i]);
                    if(score_info[i].judge_id == data.judge_id)
                    {
                        judge_total += (score_info[i].value * score_info[i].weight);
                        judge_weight_total += score_info[i].weight;
                    }
                    team_total += (score_info[i].value * score_info[i].weight);
                    team_weight_total += score_info[i].weight;
                }
            }
            total_weighted_average = team_total/team_weight_total;
            judge_weighted_average = judge_total/judge_weight_total;

            let rounded_total = total_weighted_average.toFixed(1);
            let rounded_judge = judge_weighted_average.toFixed(1);

            const averages = {
                event_id: data.event_id,
                judge_id: data.judge_id,
                team_id: data.team_id,
                judge_avg: rounded_judge,
                team_avg: rounded_total
            }
            dispatch(updateDB("averages", averages, data.event_id))
            dispatch(success('nice'))
        })
    }

    function success(myTeamInfo) {
        return { type: "calc info", myTeamInfo };
    }

    function request(info) {
        return { type: userConstants.REQUEST, info };
    }
}

function updateDB(tableType, data, eventId) {
    return(dispatch) => {
    dispatch(request("Updating " + tableType + " Table"));
    socket.emit('UpdateTables', JSON.stringify({tableType, data, eventId}),
    function(res) {
        const {message, err} = JSON.parse(res);
        //dispatch(GetMyTeamInfo(data));
        if(tableType == "scores")
        {
            const input = JSON.stringify(data)
            const value = JSON.parse(input)
            const obj = {
                event_id: eventId,
                judge_id: data.judge_id,
                team_id: data.tid,
                question_id: data.qid
            }
            dispatch(calculateAverage(obj));
        }
        if(tableType ==  "publish")
        {
            reroute('admin/dashboard');
        }
        if(tableType == "averages") {
            dispatch(GetMyTeamInfo(eventId, data.judge_id))
        }
        if(tableType == "Event")
        {
            reroute('admin/dashboard');
        }
        if(message) dispatch(success(message));
        if(err) dispatch(error(err));
    }
    )
    }
    function success(message) {
        console.log(message)
    return { type: userConstants.SUCCESS, message };
    }
    function request(info) {
    return { type: userConstants.REQUEST, info };
    }
    function error(err) {
    return { type: userConstants.FAILURE, err };
    }
}

function mapJudgeTrack(eventId) {
    return (dispatch) => {
        dispatch(request("Mapping Judges with Teams"));

        socket.emit('JudgeTrack', JSON.stringify({eventId}),
        function(res) {
            //reroute('admin/dashboard');
        });
    };
    function request(info) {
        return { type: userConstants.REQUEST, info };
    }
}

function GetMyTeamInfo(eid, jid) // judge pov will be track oriented
{
    return(dispatch) => {
        dispatch(request("Fetching My Team Info"));
        socket.emit('GetMyTeamInfo', JSON.stringify({eid, jid}), 
        function(res)
        {
            const {myTeamInfo} = JSON.parse(res)
            if(myTeamInfo){
                console.log(myTeamInfo)
                dispatch(success({myTeamInfo}));
            } 
            else 
            {
                //Dispatch error message here
            }

        })
    }

    function success(myTeamInfo) {
        return { type: userConstants.GETMYTEAMINFO, myTeamInfo };
    }

    function request(info) {
        return { type: userConstants.REQUEST, info };
    }

    
}

function AttemptPublish(eid, user) // judge pov will be track oriented
{
    console.log("AttemptPublish")
    return(dispatch) => {
        dispatch(request("Fetching Event Status Info"));
        console.log("in return")

        socket.emit('AttemptPublish', JSON.stringify({eid, user}), 
        function(res)
        {
            const {status} = JSON.parse(res)
            if(status)
            {
                console.log("Map Judge Track Being Called")
                console.log(eid)
                
                dispatch(mapJudgeTrack(eid))
                dispatch(success("Published!"));
                reroute('admin/dashboard');
            }
            else
            {
                //display error
                dispatch(error("Missing Data!"));
            }
        })
    }
    function success(results) {
        return { type: userConstants.GETRESULTS, results};
    }

    function request(info) {
        return { type: userConstants.REQUEST, info };
    }

    function error(err) {
        return { type: userConstants.FAILURE, err };
    }
}

function getJudgeResults(event_id, teamID) {
    return(dispatch) => {
        dispatch(request('Gathering Judges Results For Team '+teamID));
        socket.emit('GettingJudgesComments', JSON.stringify({event_id, teamID}),
        function(res) {
            const {results} = JSON.parse(res);
            if(results) {
                dispatch(success({results}));
            }
        })
    }

    function success(results) {
        return { type: userConstants.GETRESULTS, results};
    }

    function request(info) {
        return { type: userConstants.REQUEST, info };
    }
}

function reroute(url) {
    history.push(`/${url}`);
    setTimeout(() => {
        window.location.reload(false);
    }, 0)
}


