import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { Offcanvas, Navbar, Nav, Container } from 'react-bootstrap';
import { FaBars } from 'react-icons/fa';
import BSU_Logo from '../images/VC_Logo.png'
import { userActions } from '../actions/useractions';
import home from '../pages/home';

class NavBar extends Component {
    constructor(props) {
        super(props)
        this.state = {
        }
    }
    logout() {
        this.props.signOut(this.props.session.email)
    }
    render() {
        return (
            <Fragment>
            <Navbar key={false} expand={false} className="mb-3 z-1 p-0">
                <Container fluid className="justify-content-center">
                    <img src={BSU_Logo} className='imgTitle'></img>
                    <Navbar.Brand href="/" >
                        <h1 className='nav_title'>Venture College Pitch Competitions</h1>
                    </Navbar.Brand>
                    {this.props.session.clearance ? <button className='floatright logout-btn' onClick={() => this.logout()}>Sign out</button> : null}
                </Container>
            </Navbar>
            <hr/>
        </Fragment>
        );
    }
}

const actionCreators = {
    signOut: userActions.signOut
};

function mapState(state) {
    const {session} = state;
    return {session};
  }

const connection = connect(mapState, actionCreators)(NavBar);
export { connection as NavBar };